/**
 * Utility functions to profile slow function calls. This profiling code is
 * designed to capture cascading events, where the first instance of an event
 * (e.g., a button click) triggers a bunch of subsequent events (e.g., redraws, selectors)
 * Here is how the profiler is designed:
 *
 * - Whenever a `measure` call occurs, we collect the event in a temporary buffer (`tempEvents`).
 * - On the first instance a `measure` call occurs, we set a timer with a recurring interval
 *   of  `EVENT_TRACK_INTERVAL` milliseconds.
 * - Every `EVENT_TRACK_INTERVAL` milliseconds, we move the temporary buffer to a more
 *   complete buffer (`events`).
 * - The first time we look at the temporary buffer and it's empty, we assume we have
 *   seen all cascading events, and call `sendAnalytics` based on the previously captured events.
 */

import first from 'lodash/first'
import last from 'lodash/last'
import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'

import analytics from 'site_builder/utils/analytics.es6.js'

export const EVENT_CATEGORY = 'js_function_profiling'
export const EVENT_TIME_LIMIT_RECURRING = 50
export const EVENT_TRACK_INTERVAL = 1000

/**
 * Events, collected between user inactivity.
 */
let events = {}

/**
 * Events, collected per second. Are cleared every sec.
 */
let tempEvents = {}

/**
 * Id for setInterval
 */
let intervalId

/**
 * Store tempEvent.
 */
function collectTempEvent(event) {
  const key = `${event.eventAction}.${event.eventLabel}`

  if (tempEvents[key]) {
    tempEvents[key].push(event)
  } else {
    tempEvents[key] = [event]
  }
}

/**
 * Send collected data to Google Analytics. This function will filter out all events
 * that take less than EVENT_TIME_LIMIT_RECURRING time to execute.
 *
 * @param {Object} data
 */
function sendAnalytics(data) {
  Object.keys(data).forEach(function (key) {
    const occurences = data[key]
    const totalTime = reduce(
      occurences,
      function (result, event) {
        return result + event.eventValue
      },
      0
    )

    if (occurences.length >= 1 && totalTime > EVENT_TIME_LIMIT_RECURRING) {
      const sorted = sortBy(occurences, (item) => item.eventTimestamp)
      const event = occurences[0]
      const wallClockTime = last(sorted).eventTimestamp - first(sorted).eventTimestamp
      const data = {
        category: EVENT_CATEGORY,
        action: 'periodic_event_summary',
        label: `${event.eventAction}.${event.eventLabel}`,
        value: totalTime,
        wallClockTime: wallClockTime,
        numCalls: occurences.length,
        totalTime,
      }

      analytics.trackEvent(data.category, data.action, data.label, null, {
        value: data.value,
        wallClockTime: data.wallClockTime,
        totalTime: totalTime,
        numCalls: data.numCalls,
      })
    }
  })
}

/**
 * Copy tempEvents to events object. Called every EVENT_TRACK_INTERVAL ms.
 */
function collectAllCalls() {
  const keys = Object.keys(tempEvents)

  if (keys.length === 0) {
    clearInterval(intervalId)
    sendAnalytics(events)

    intervalId = undefined
    events = {}
  }

  Object.keys(tempEvents).forEach(function (key) {
    if (events[key]) {
      events[key] = events[key].concat(tempEvents[key])
    } else {
      events[key] = tempEvents[key]
    }
  })

  tempEvents = {}
}

/**
 * Public interface.
 */
export default {
  measure: function (target, format) {
    return function (...args) {
      const start = Date.now()
      const result = target(...args)
      const end = Date.now() - start

      if (intervalId === undefined) {
        intervalId = setInterval(collectAllCalls, EVENT_TRACK_INTERVAL)
      }

      collectTempEvent(format(...[...args, end, start]))

      return result
    }
  },

  formatter: {
    reducer: function (name) {
      return function (state, action, time, timestamp) {
        return {
          eventCategory: EVENT_CATEGORY,
          eventAction: 'reducer',
          eventLabel: `${name}.${action.type}`,
          eventValue: time,
          eventTimestamp: timestamp,
        }
      }
    },

    selector: function (name) {
      return function (...args) {
        return {
          eventCategory: EVENT_CATEGORY,
          eventAction: 'selector',
          eventLabel: name,
          eventValue: args.splice(-2, 1)[0],
          eventTimestamp: last(args),
        }
      }
    },
  },
}
