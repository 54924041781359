/* global Sentry */

import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import { EVENT_CATEGORY as PROFILING_EVENT_CATEGORY } from 'common/js/profile.es6.js'

// Set up the global Hubspot event queue.
const _hsq = (window._hsq = window._hsq || [])

const MILESTONE_EVENTS = new Set([
  'signup.pageview',
  'signup.business-description-animation-viewed',
  'signup.end',
  'design-suite.pageview',
  'design-suite.account-activated',
  'design-suite.deploy.staging-preview-click',
  'design-suite.deploy.production-publish-click',
  'design-suite.payments.purchase-click-success',
  'design-suite.payments.checkout-screen-loaded',
  'design-suite.payments.plans-screen-loaded',
  'design-suite.onboarding.generated-content-error',
  'design-suite.onboarding.generated-content-loaded',
  'design-suite.onboarding.generated-content-timeout',
  'design-suite.onboarding.rating-reveal-experience-loaded',
  'design-suite.onboarding.single-step-reveal-loaded',
  'design-suite.onboarding.reveal-initial-theme',
  'design-suite.onboarding.reveal-clicked-continue',
  'design-suite.onboarding.reveal-clicked-change-business',
  'design-suite.onboarding.reveal-clicked-change-style',
  'design-suite.onboarding.reveal-clicked-change-logo',
  'design-suite.onboarding.reveal-clicked-change-content',
  'design-suite.onboarding.reveal-clicked-change-rebuild',
  'design-suite.onboarding.reveal-clicked-change-generate-content',
  'design-suite.onboarding.style-search-loaded',
  'design-suite.domains.domains-pane-loaded',
  'design-suite.regenerate-buttons.live-preview-gear-button-click',
  'design-suite.layout-switcher.change-layout-click',
  'design-suite.layout-switcher.regenerate-layout-click',
  'design-suite.mobile-nav.show-side-pane-toggle',
  'design-suite.mobile-nav.side-pane-open',
  'design-suite.mobile-nav.side-pane-close',
  'design-suite.dashboard.dashboard-screen-loaded',
])

const PROFILING_EVENTS = new Set([PROFILING_EVENT_CATEGORY])

function identify(trackingId, email) {
  _hsq.push([
    'identify',
    {
      email: email,
      id: trackingId,
    },
  ])
}

function trackEvent(category, action, label = '', clientUid = '', data = {}) {
  const trackingId = get(b12Context, 'trackingId', undefined)
  const b12ContextClientUid = get(b12Context, 'client.uid', '')
  clientUid = clientUid || b12ContextClientUid
  const customer = get(b12Context, 'client_team_member.id')
    ? get(b12Context, 'client_team_member.user', {})
    : get(b12Context, 'primary_member.user', {})
  const firstName = customer.first_name || ''
  const lastName = customer.last_name || ''
  const phone = get(b12Context, 'client.phone', '')
  const city = get(b12Context, 'client.city', '')
  const country = get(b12Context, 'client.country', '')
  const stateProvince = get(b12Context, 'client.state_province', '')
  const postalCode = get(b12Context, 'client.postal_code', '')

  data.clientUid = clientUid
  const eventName = `${category}.${action}`
  data.firstName = firstName
  data.lastName = lastName
  data.phone = phone
  data.city = city
  data.country = country
  data.stateProvince = stateProvince
  data.postalCode = postalCode
  data.category = category
  data.action = action
  data.label = label
  data.trackingId = trackingId
  // JSON schema verison, update this if the schema changes.
  data.VERSION = 1
  // Services other than GTM get the complete event name, GTM only cares about action
  data.event = eventName

  // Certain parts of the experience (e.g., the Design Suite) are
  // accessed by experts on behalf of clients. We log `isExpert`
  // and the actual user's email to track this work.
  data.isExpert = get(b12Context, 'is_client_executive')
  data.userEmail = get(b12Context, 'user.email')

  // Send data to Google Tag Manager
  const gtmData = cloneDeep(data) // The copy is necessary since if we modify the object after, GTM sends the wrong data.
  gtmData.event = action // required for GTM to filter for events
  if (window.dataLayer) {
    window.dataLayer.push(gtmData)
  }

  // Send data to snowplow
  if (window.snowplow && !PROFILING_EVENTS.has(category)) {
    window.snowplow('trackStructEvent', category, action, label, JSON.stringify(data))
  }

  // For products like FullStory and Hubspot, we only track milestone events.
  const dottedLabel = label ? `.${label}` : ''
  const fullEventName = `${eventName}${dottedLabel}`
  if (MILESTONE_EVENTS.has(fullEventName)) {
    if (window.FS) {
      window.FS.event(fullEventName, data)
    }
    _hsq.push(['trackEvent', { id: fullEventName.replace(/\./g, ' ') }])
  }

  // Let's see how long people stay on the page, marking them at 30
  // second intervals
  if (action === 'pageview') {
    trackTimeUserSpendOnPage(action, category, label, clientUid)
  }
}

function trackTimeUserSpendOnPage(action, category, label, clientUid) {
  const newActionName = `${action}-keepalive`
  let count = 0
  const interval = 30
  const intervalId = setInterval(() => {
    count++
    const newData = {
      timeSpent: count * interval, // time spent on the page in seconds
    }
    trackEvent(category, newActionName, label, clientUid, newData)
    if (count === 10) {
      clearInterval(intervalId)
    }
  }, interval * 1000)
}

/**
 * Logs exception to Sentry.
 */
function logException(e) {
  // Sentry is accessible only in prod env.
  typeof Sentry !== 'undefined' ? Sentry.captureException(e) : console.error(e)
}

/**
 * Logs error to Sentry.
 */
function logError(message, additionalData = {}) {
  // Sentry is accessible only in prod env.
  if (typeof Sentry !== 'undefined') {
    Sentry.withScope(function (scope) {
      scope.setExtra('additionalData', additionalData)
      Sentry.captureException(message)
    })
  } else {
    console.error(message, additionalData)
  }
}

export default { identify, trackEvent, logException, logError }
